<template>
  <b-card-code title="Remessa" no-body>
    <b-card-body>
      <b-row>
        <!-- Mês  -->
        <b-col md="2">
          <b-form-group label="Mês" label-for="mes">
            <b-form-select id="mes" name="mes" v-model="filtro.competencia" :options="competenciaOpcoes"> </b-form-select>
          </b-form-group>
        </b-col>

        <!-- Ano  -->
        <b-col md="2">
          <b-form-group label="Ano" label-for="ano">
            <b-form-select id="ano" name="ano" v-model="filtro.exercicio" :options="exercicioOpcoes"></b-form-select>
          </b-form-group>
        </b-col>

        <!-- Actions -->
        <b-col md="auto">
          <b-button
            :disabled="isBusy"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            style="margin-top: 25px; width: 160px"
            @click.prevent="carregarGrid"
          >
            {{ !isBusy ? 'Pesquisar' : '' }}
            <b-spinner small v-if="isBusy" />
          </b-button>
        </b-col>

        <!-- Remessa -->
        <b-col md="auto">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            style="margin-top: 25px; width: 160px"
            variant="danger"
            @click.prevent="authESalve('remessa')"
          >
            Gerar Remessa
          </b-button>
        </b-col>

        <b-col md="auto" v-if="items.length > 0">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            style="margin-top: 25px"
            variant="success"
            @click="pagamentoHandler(items)"
          >
            Confirmar Pagamento
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>

    <!-- Tabela -->
    <b-table
      id="tabelaPagamento"
      v-show="items.length > 0"
      striped
      responsive
      class="position-relative rounded"
      :per-page="pesquisar.perPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :current-page="pesquisar.currentPage"
      :busy="isBusy"
    >
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong>Carregando...</strong>
        </div>
      </template>

      <template #cell(opcoes)="row">
        <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
          <template #button-content>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              title="Este botão contém as opções disponíveis para este registro"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>
          <b-dropdown-item :disabled="row.item.statusPagamento === 'Pago'" @click="pagamentoHandler(row.item)">
            <feather-icon icon="CheckIcon" size="16" />
            <span class="align-middle ml-50">Confirmar Pagamento da Proposta</span>
          </b-dropdown-item>

          <b-dropdown-item :disabled="row.item.statusPagamentoIOF === 'IOFPago'" @click="pagamentoIOFHandler(row.item)">
            <feather-icon icon="CheckIcon" size="16" />
            <span class="align-middle ml-50">Confirmar Pagamento IOF</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>

      <!-- Campo Nº Contrato -->
      <template #cell(codigo)="row">
        {{ String(row.item.financeiro.codigo).padStart(6, '0') }}
      </template>

      <!-- Campo Nome Tomador -->
      <template #cell(nome)="row">
        <div>{{ row.item.financeiro.segurado.tomador.nomeCompleto }}</div>
        <div>{{ row.item.financeiro.segurado.matricula }}</div>
      </template>

      <!-- Campo Valor Banco -->
      <template #cell(banco)="row">
        <div>
          {{
            row.item.financeiro.segurado.tomador.banco
              ? row.item.financeiro.segurado.tomador.banco.nome
              : 'Banco não cadastrado'
          }}
        </div>
      </template>

      <!-- Campo Valor Agência -->
      <template #cell(agencia)="row">
        <div>
          {{
            row.item.financeiro.segurado.tomador.dadoBancarioAgencia
              ? String(row.item.financeiro.segurado.tomador.dadoBancarioAgencia) +
                ' - ' +
                String(row.item.financeiro.segurado.tomador.dadoBancarioAgenciaDV)
              : 'Agência não cadastrada'
          }}
        </div>
      </template>

      <!-- Campo Valor Conta -->
      <template #cell(conta)="row">
        <div>
          {{
            row.item.financeiro.segurado.tomador.dadoBancarioConta
              ? String(row.item.financeiro.segurado.tomador.dadoBancarioConta) +
                ' - ' +
                String(row.item.financeiro.segurado.tomador.dadoBancarioContaDV)
              : 'Conta não cadastrada'
          }}
        </div>
      </template>

      <!-- Campo Valor Montante -->
      <template #cell(valorContrato)="row">
        {{ formatarValor(row.item.financeiro.valorBaseCalculo) }}
      </template>

      <!-- Campo Valor IOF -->
      <template #cell(valorIOF)="row">
        {{ formatarValor(row.item.financeiro.valorIOF) }}
      </template>

      <template #cell(dataPagamentoIOF)="row">
        {{ formatarData(row.item.dataPagamentoIOF) }}
      </template>

      <template #cell(dataPagamentoRemessa)="row">
        {{ formatarData(row.item.dataPagamentoRemessa) }}
      </template>

      <!-- Campo Status Pagamento -->
      <template #cell(statusPagamento)="row">
        <b-badge pill :variant="row.item.statusPagamento ? status[0][row.item.statusPagamento] : status[0][0]">
          {{ row.item.statusPagamento ? row.item.statusPagamento : 'Sem Status' }}
        </b-badge>

        <!-- Status Pagamento IOF -->
        <b-badge pill :variant="row.item.statusPagamentoIOF ? statusIOF[0][row.item.statusPagamentoIOF] : 'primary'">
          {{ row.item.statusPagamentoIOF ? statusOpcoesIOF[row.item.statusPagamentoIOF] : 'Sem Status IOF' }}
        </b-badge>
      </template>
    </b-table>

    <!-- Paginação -->
    <b-card-body v-if="items.length > 0" class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        style="align-items: center"
        label="Por Página"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select id="perPageSelectInd" v-model="pesquisar.perPage" size="sm" inline :options="pageOptions" />
      </b-form-group>

      <!-- pagination -->
      <b-pagination
        v-model="pesquisar.currentPage"
        :total-rows="totalRows"
        :per-page="pesquisar.perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card-body>

    <!-- Modal de Senha -->
    <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
      <AutenticaSenha @autenticarEvent="autenticarMethod" />
    </b-modal>
    <!-- Modal Gerar Pagamento -->
    <b-modal id="modalConfirmarPagamento" centered ref="modalConfirmarPagamento" title="Gerar Pagamento">
      <p class="mb-0 mt-1">Selecione a data de pagamento:</p>
      <b-form-group label="" label-for="dataPagamentoRemessa">
        <validation-provider #default="{ errors }" name="Data de Pagamento da Rememssa" rules="required">
          <b-form-datepicker
            id="dataPagamentoRemessa"
            v-model="selectedDate"
            placeholder="DD/MM/AAAA"
            :hide-header="true"
            :initial-date="new Date()"
            label-help=""
            :max="new Date()"
            label-calendar="Calendário"
            label-close-button="Fechar"
            label-current-month="Mês atual"
            label-next-decade="Próxima década"
            label-next-month="Próximo mês"
            label-next-year="Próximo ano"
            label-prev-decade="Década anterior"
            label-prev-month="Mês anterior"
            label-prev-year="Ano anterior"
            label-select-month="Selecione o mês"
            label-select-year="Selecione o ano"
            label-selected="Selecionado"
            label-today="Hoje"
            :date-format-options="{ year: 'numeric', month: '2-digit', day: 'numeric' }"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <template v-slot:modal-footer>
        <b-button variant="primary" @click.prevent="authESalve('pagamento')">Gerar</b-button>
      </template>
    </b-modal>

    <!-- Modal Gerar Pagamento IOF -->
    <b-modal id="modalConfirmarPagamentoIOF" centered ref="modalConfirmarPagamentoIOF" title="Gerar Pagamento IOF">
      <p class="mb-0 mt-1">Selecione a data de pagamento para o IOF:</p>
      <b-form-group label="" label-for="dataPagamentoIOF">
        <validation-provider #default="{ errors }" name="Data de Pagamento do IOF" rules="required">
          <b-form-datepicker
            id="dataPagamentoIOF"
            v-model="selectedDate"
            placeholder="DD/MM/AAAA"
            :hide-header="true"
            :initial-date="new Date()"
            label-help=""
            :max="new Date()"
            label-calendar="Calendário"
            label-close-button="Fechar"
            label-current-month="Mês atual"
            label-next-decade="Próxima década"
            label-next-month="Próximo mês"
            label-next-year="Próximo ano"
            label-prev-decade="Década anterior"
            label-prev-month="Mês anterior"
            label-prev-year="Ano anterior"
            label-select-month="Selecione o mês"
            label-select-year="Selecione o ano"
            label-selected="Selecionado"
            label-today="Hoje"
            :date-format-options="{ year: 'numeric', month: '2-digit', day: 'numeric' }"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <template v-slot:modal-footer>
        <b-button variant="primary" @click.prevent="authESalve('pagamentoIOF')">Gerar</b-button>
      </template>
    </b-modal>
  </b-card-code>
</template>

<script>
  //validacao
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import { formatarParaNumber, formatarValor, isLetter, formatarData } from '@/libs/utils'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'
  import NaoAutorizadoModal from '@/views/error/nao-autorizado-modal.vue'
  import Cleave from 'vue-cleave-component'
  import { forEach } from 'mathjs'
  import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

  export default {
    components: {
      ValidationObserver,
      ValidationProvider,
      required,
      Cleave,
      AutenticaSenha,
      NaoAutorizadoModal,
      formatarValor,
      BCardCode,
      FeatherIcon,
      formatarData,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        filtro: { exercicio: '', competencia: '', valorPatrimonio: 0.0, valorMontante: 0.0 },
        isBusy: false,
        number: {},
        formatarValor,
        formatarData,
        isLetter,
        cleaveCurrency: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          delimiter: '.',
          numeralDecimalMark: ',',
          numeralDecimalScale: 2,
        },
        pesquisar: { perPage: 25, currentPage: 1 },
        exercicioOpcoes: [
          { value: '', text: 'Selecione' },
          { value: '2025', text: '2025' },
          { value: '2024', text: '2024' },
          { value: '2023', text: '2023' },
          { value: '2022', text: '2022' },
          { value: '2021', text: '2021' },
          { value: '2020', text: '2020' },
          { value: '2019', text: '2019' },
          { value: '2018', text: '2018' },
          { value: '2017', text: '2017' },
          { value: '2016', text: '2016' },
          { value: '2015', text: '2015' },
        ],
        competenciaOpcoes: [
          { value: '', text: 'Selecione' },
          { value: 1, text: 'Janeiro' },
          { value: 2, text: 'Fevereiro' },
          { value: 3, text: 'Março' },
          { value: 4, text: 'Abril' },
          { value: 5, text: 'Maio' },
          { value: 6, text: 'Junho' },
          { value: 7, text: 'Julho' },
          { value: 8, text: 'Agosto' },
          { value: 9, text: 'Setembro' },
          { value: 10, text: 'Outubro' },
          { value: 11, text: 'Novembro' },
          { value: 12, text: 'Dezembro' },
        ],
        acesso: {},

        //Campos tabela
        fields: [
          { key: 'opcoes', label: 'Opções' },
          { key: 'financeiro.codigo', label: 'Nº Contrato', sortable: true },
          { key: 'financeiro.segurado.tomador.nomeCompleto', label: 'Nome', sortable: true },
          { key: 'financeiro.segurado.matricula', label: 'Banco', sortable: true },
          { key: 'agencia', label: 'Agência' },
          { key: 'conta', label: 'Conta' },
          { key: 'valorContrato', label: 'Valor da Proposta' },
          { key: 'valorIOF', label: 'Valor do IOF' },
          { key: 'dataPagamentoRemessa', label: 'Data pagamento Proposta' },
          { key: 'dataPagamentoIOF', label: 'Data Pagamento IOF' },
          { key: 'statusPagamento', label: 'Status' },
        ],
        items: [],
        statusOpcoes: [
          { value: 'Pendente', text: 'Pendente' },
          { value: 'Gerado', text: 'Gerado' },
          { value: 'Confirmado', text: 'Confirmado' },
        ],
        statusOpcoesIOF: {
          IOFPendente: 'IOF Pendente',
          IOFPago: 'IOF Pago',
        },
        status: [
          {
            Consolidado: 'success',
            Pendente: 'warning',
            Quitado: 'info',
          },
        ],
        statusIOF: [
          {
            IOFPendente: 'warning',
          },
        ],

        //Padrão Tabela
        sortBy: '',
        totalRows: 1,
        sortDesc: false,
        sortDirection: 'asc',
        pageOptions: [10, 25, 50, 100],
        pesquisar: {
          tipoOpcoes: 1,
          descricao: '',
          perPage: 10,
          currentPage: 1,
        },
        striped: true,
        selectedDate: new Date(),
        tipoPagamento: false,
        contratosParaPagamento: [],
        mode: '',
      }
    },
    mounted() {
      this.filtro.exercicio = new Date().getFullYear()
      this.filtro.competencia = new Date().getMonth() + 1
    },
    methods: {
      authESalve(tipo) {
        if (this.items.length > 0) {
          if ((this.userData.role = 'master' || this.userData.GrupoAcesso.FinanceiroPagamento)) {
            if (tipo == 'remessa') {
              this.tipoPagamento = false
              if (!this.verificaStatus()) {
                return
              }
            } else if (tipo == 'pagamento') {
              this.tipoPagamento = true
            } else if (tipo == 'pagamentoIOF') {
              this.tipoPagamento = null
            }
            this.mode = 'authESalve'
            this.$refs['modal-auth-senha'].show()
          } else this.$message.error('O usuário não tem permissão para gerar remessa, verifique o grupo de acesso!')
        } else this.$message.error('Não há dados para o filtro informado!')
      },
      verificaStatus() {
        const temPagamentoPendente = this.items.some((item) => item.statusPagamento === 'Pendente')

        if (!temPagamentoPendente) {
          this.$message.error('Para gerar uma remessa, é necessário ter pelo menos um pagamento com status "Pendente".')
          return false
        }
        return true
      },

      autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()

          if (this.mode == 'authESalve') {
            if (this.tipoPagamento === null) {
              this.gerarPagamentoIOF(this.contratosParaPagamento)
            } else if (this.tipoPagamento) {
              this.gerarPagamento(this.contratosParaPagamento)
            } else {
              this.gerarRemessa()
            }
          }
        }
      },
      async gerarRemessa() {
        // this.$message.error("Layout CNAB não encontrado da Unidade Gestora. Favor entrar em contato para realizar a remessa!")
        // download("hello.txt","This is the content of my file :)");
        // this.download("Remessa.txt",
        //  `04700000         209314825000155402249              0002900000004011123 SERGIPEPREVIDENCIA            BANCO DO ESTADO DE SERGIPE S.A          11509202115270301004608200000
        //   04700011C9001042 209314825000155402249              0002900000004011123 SERGIPEPREVIDENCIA                                                    PRACA GENERAL VALADAO         00032               ARACAJU             49010520SE
        //   0470001300001A00000034100814 0000000088126 TEREZINHA GOMES PEREIRA       0153020000670947849117092021BRL000000000000000000000000387708                    00000000000000000000000                                               10   0
        //   0470001300002B   100006709478491DE BELEM                      01128               CAMPO GRANDE   RECIFE              52030000PE17092021000000000000000000000000000000000000000000000000000000000000000000000000000
        //   04700015         000004000000000000387708000000000000000000000000

        //   04799999         000001000006000000                                                                                                                                                                                                             `)
        this.isBusy = true
        let exercicio = formatarParaNumber(this.filtro.exercicio)
        let mes = formatarParaNumber(this.filtro.competencia)
        let competencia = exercicio * 100 + mes
        let institutoId = this.userData.institutoSelecionado
        let parametros = {
          institutoId: institutoId,
          competencia: competencia,
        }

        let url = 'https://www.agendasistemas.com.br/ExportadorBancario/api/ExportadorBancario/Remessa'

        // Monta JSON para ser enviado pro WebService
        useJwt
          .post('financeiro/CriaRemessaBancaria', parametros)
          .then(async (response) => {
            this.isBusy = false
            let respostaAPI = await useJwt
              .post(url, response.data)
              .then((success) => {
                // parametros = {
                //   ...parametros,
                //   userId: this.userData.id,
                //   arquivoRemessa: success.data,
                // }

                this.downloadArquivoRemessa(success.data, competencia)

                // TODO: Criar regra de agendamento
                // useJwt.post('financeiro/CriaAgendamentoRemessa', parametros)
              })
              .catch((error) => {
                console.error(error)
              })

            this.$message.success('Remessa criada com sucesso!')
          })
          .catch((error) => {
            console.error(error)
            this.isBusy = false
            this.$message.error('Erro ao criar a remessa bancária!')
          })
      },
      downloadArquivoRemessa(binario, competencia) {
        // Converte a string binária em um array de bytes
        let binaryData = atob(binario)

        // Cria um array de bytes
        let byteArray = new Uint8Array(binaryData.length)

        // Preenche o array com os bytes convertidos
        for (let i = 0; i < binaryData.length; i++) {
          byteArray[i] = binaryData.charCodeAt(i)
        }

        // Cria um Blob (objeto binário) a partir do array de bytes
        let blob = new Blob([byteArray])

        // Cria uma URL temporária para o Blob
        let url = URL.createObjectURL(blob)

        // Cria um link para fazer o download do arquivo
        let link = document.createElement('a')
        link.href = url

        // Define o nome do arquivo
        let vDataHoraGeracao = new Date()
        // let nomeArquivo = vDataHoraGeracao.toISOString().replace(/[-T:]/g, "").slice(0, -5) + ".txt";

        competencia
        let nomeArquivo = this.userData.Instituto.codigoConvenio
          ? String(this.userData.Instituto.codigoConvenio) + '.txt'
          : competencia + '.txt'
        link.download = nomeArquivo

        // Simula um clique no link para iniciar o download
        link.click()

        // Limpa a URL temporária
        URL.revokeObjectURL(url)
      },
      carregarGrid() {
        this.isBusy = true
        let exercicio = formatarParaNumber(this.filtro.exercicio)
        let mes = formatarParaNumber(this.filtro.competencia)
        let competencia = exercicio * 100 + mes
        let institutoId = this.userData.institutoSelecionado
        let parametros = {
          institutoId: institutoId,
          competencia: competencia,
        }

        useJwt
          .pesquisar('pagamento/GetPagamentosPendentes', parametros)
          .then((response) => {
            this.isBusy = false
            this.items = response.data
            this.totalRows = response.data.length

            if (!this.totalRows) {
              this.$message.error('Nenhum registro encontrado!')
            }
          })
          .catch((error) => {
            this.isBusy = false
            this.$message.error('Atenção! ' + error.response.data)
            console.error(error)
          })
      },
      gerarPagamento(data) {
        this.isBusy = true
        try {
          for (let dados of data) {
            let dadosPagamento = {
              id: dados.financeiroId,
              dataPagamento: new Date(this.selectedDate),
            }
            useJwt
              .put('financeiro/PagamentoRemessa', dadosPagamento)
              .catch((error) => {
                console.error(error)
              })
              .finally(() => {
            useJwt.put('financeiro/PagamentoRemessa', dadosPagamento).catch((error) => {
              console.error(error)
            })
            .finally(() => {
                this.carregarGrid()
              })
            })
          }
          this.isBusy = false
          this.$message.success('Pagamento gerado com sucesso!')
          this.$refs['modalConfirmarPagamento'].hide()
        } catch (error) {
          this.isBusy = false
          this.$message.error('Erro ao gerar o pagamento!')
          console.error(error)
        }
      },
      gerarPagamentoIOF(data) {
        this.isBusy = true
        try {
          for (let dados of data) {
            let dadosPagamentoIOF = {
              id: dados.financeiroId,
              dataPagamentoIOF: new Date(this.selectedDate),
            }
            useJwt
              .put('financeiro/pagamentoIOF', dadosPagamentoIOF)
              .catch((error) => {
                console.error(error)
              })
              .finally(() => {
                this.carregarGrid()
              })
          }
          this.isBusy = false
          this.$message.success('Pagamento gerado com sucesso!')
          this.$refs['modalConfirmarPagamentoIOF'].hide()
        } catch (error) {
          this.isBusy = false
          this.$message.error('Erro ao gerar o pagamento!')
          console.error(error)
        }
      },
      pagamentoHandler(item) {
        if (Array.isArray(item)) {
          item = item.filter((item) => item.statusPagamento === 'Pendente')
          this.contratosParaPagamento = item
        } else {
          this.contratosParaPagamento = [item]
        }
        this.$refs['modalConfirmarPagamento'].show()
        this.carregarGrid()
      },
      pagamentoIOFHandler(item) {
        if (Array.isArray(item)) {
          item = item.filter((item) => item.statusPagamentoIOF === 'IOFPendente')
          this.contratosParaPagamento = item
        } else {
          this.contratosParaPagamento = [item]
        }
        this.$refs['modalConfirmarPagamentoIOF'].show()
      },
    },
  }
</script>
<style>
  th {
    vertical-align: middle !important;
  }
</style>
